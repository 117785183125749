import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthStore } from "../store/authStore";

const AdminRoute = ({ children }) => {
  // const { admin } = useAuthStore();
  const token = localStorage.getItem("katana-admin-token");
  const role = localStorage.getItem("role");
  if ((token && role === "admin") || role === "manager") {
    return children;
  }
  return <Navigate to={"/"} replace />;
};

export default AdminRoute;
