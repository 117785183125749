import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { useKeysStore } from "../../store/keysStore";

export const SearchBar = ({ resetStates }) => {
  const { getAllKeys, searchKeys, getAllKeysLoading } = useKeysStore();

  const [searchValue, setSearchValue] = useState("");

  const handleSearchButtonClick = () => {
    if (searchValue === "") {
      getAllKeys(1, 10, "primary", "all");
    } else {
      searchKeys(searchValue);
    }
    resetStates();
  };

  return (
    <TextField
      variant="standard"
      placeholder="Search By Full Key/UserId"
      value={searchValue}
      onChange={(e) => setSearchValue(e.target.value)}
      InputProps={{
        endAdornment: (
          <IconButton
            edge="end"
            disabled={getAllKeysLoading}
            onClick={handleSearchButtonClick}
          >
            <SearchIcon />
          </IconButton>
        ),
      }}
    />
  );
};
