import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { Form, Button } from "react-bootstrap";
import { useAuthStore } from "../../store/authStore";
import { useNavigate } from "react-router-dom";
import { useKeysStore } from "../../store/keysStore";

export const Login = () => {
  const navigate = useNavigate();
  const { clearKeysState } = useKeysStore();
  const { loading, adminLogin } = useAuthStore();

  const [values, setValues] = useState({ email: "", password: "" });

  const handleLogin = (e) => {
    e.preventDefault();
    clearKeysState();
    if (values.email && values.password) {
      adminLogin({ ...values, navigate });
    } else {
      toast.error("Please Enter Values");
    }
  };
  return (
    <div className="d-flex justify-content-center">
      <Form className="mt-3" style={{ width: "20rem" }}>
        <Form.Group className="mb-3 text-start">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            value={values.email}
            placeholder="Enter email"
            onChange={(e) => setValues({ ...values, email: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="text-start">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            value={values.password}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleLogin(e);
            }}
            onChange={(e) => setValues({ ...values, password: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-1 text-end">
          <Form.Label
            style={{ cursor: "pointer", color: "grey" }}
            onClick={() => navigate("/forget-password")}
          >
            Forget Password?
          </Form.Label>
        </Form.Group>
        <Button
          className="w-50"
          variant="primary"
          disabled={loading}
          onClick={handleLogin}
        >
          {loading ? "Logging In..." : "Login"}
        </Button>
      </Form>
    </div>
  );
};
