import React from "react";
import { Home } from "../components/Home";
import PrivateRoute from "./PrivateRoute";
import { Login } from "../components/auth/Login";
import { ResetPassword } from "../components/auth/ResetPassword";
import { ForgetPassword } from "../components/auth/ForgetPassword";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AddGame from "../components/AddGame";
import GameSettings from "../components/GameSettings";
import Layout from "../layout/Layout";
import AdminRoute from "./AdminRoute";
import MintForm from "../components/AddNft";
import WhitelistTable from "../components/whiteListTable/WhitelistTable";
import OKXWhitelistTable from "../components/okxWhiteListTable/OkxWhitelistTable";
import AddTestUser from "../components/AddTestUser";
import UploadBuild from "../components/UploadBuild";

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/forget-password",
    element: <ForgetPassword />,
  },
  {
    path: "/resetPassword/:token",
    element: <ResetPassword />,
  },
  {
    path: "/",
    element: (
      <PrivateRoute>
        <Layout>
          <GameSettings />
        </Layout>
      </PrivateRoute>
    ),
  },
  {
    path: "/generate-keys",
    element: (
        <Layout>
          <Home />
        </Layout>
    ),
  },
  {
    path: "/create-user",
    element: (
      <AdminRoute>
        <Layout>
          <AddTestUser />
        </Layout>
      </AdminRoute>
    ),
  },
  {
    path: "/upload-build",
    element: (
      <AdminRoute>
        <Layout>
          <UploadBuild />
        </Layout>
      </AdminRoute>
    ),
  },
  {
    path: "/nft-create",
    element: <MintForm></MintForm>,
  },
  {
    path: "/add-game",
    element: (
      <PrivateRoute>
        <AddGame />
      </PrivateRoute>
    ),
  },
  {
    path: "/whitelist-users",
    element: (
      <PrivateRoute>
        <WhitelistTable />
      </PrivateRoute>
    ),
  },
  {
    path: "/whitelist-okx",
    element: (
      <PrivateRoute>
        <OKXWhitelistTable />
      </PrivateRoute>
    ),
  },
]);
const MainRoutes = () => {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
};

export default MainRoutes;
