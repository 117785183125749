import React from "react";
// import { KeysList } from "./KeysList";
import { TableData } from "./generateKeys/tableData";

export const Home = () => {
  return (
    <>
      <TableData />
      {/* <KeysList /> */}
    </>
  );
};
