import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { useGameStore } from "../store/gameStore";
import EditModal from "./EditModal";
import "../utils/css/styles.css";
import { DeleteConsent } from "./consent/Consents";
import { Stack } from "@mui/material";
import { convertDate, convertTime } from "../utils/Conversions";

const tableCustomStyles = {
  headCells: {
    style: {
      fontSize: "15px",
      fontWeight: "bold",
      backgroundColor: "#5B136B",
      color: "white",
    },
  },
};
const DateTableComponent = ({ gameList }) => {
  const [show, setShow] = useState(false);
  const [gameData, setGameData] = useState();
  const [deleteData, setDeleteData] = useState();
  const [deleteModal, setDeleteModal] = useState();
  const { deleteGame, editGame } = useGameStore();
  const nav = useNavigate();
  const role = localStorage.getItem("role");

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };
  const handleDeleteSubmit = (id) => {
    deleteGame(id);
    setDeleteModal(false);
  };
  const handleOpenDeleteModal = (id) => {
    setDeleteData(id);
    setDeleteModal(true);
  };

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setGameData(data);
    setShow(true);
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Game ID</span>,
      selector: (row) => row?.gameId,
      sortable: true,
      center: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Game Name</span>,
      selector: (row) => row?.name,
      sortable: true,
      center: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Drive ID</span>,
      selector: (row) => row?.googleDriveId,
      sortable: true,
      center: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Drive Exe Path</span>,
      selector: (row) => row?.googleDriveExePath,
      sortable: true,
      center: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Added On</span>,
      selector: (row) =>
        row?.createdAt &&
        `${convertDate(row?.createdAt)} ${convertTime(row?.createdAt)}`,
      sortable: true,
      center: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Version</span>,
      selector: (row) => row?.version,
      sortable: true,
      center: true,
    },
    {
      name: <span className="font-weight-bold fs-13">File Size</span>,
      selector: (row) => row?.fileSize,
      sortable: true,
      center: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Total Files</span>,
      selector: (row) => row?.totalFiles,
      sortable: true,
      center: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Select Option</span>,
      selector: (row) => row?.type && row?.type,
      sortable: true,
      width: "200px",
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      cell: (id) => {
        return (
          <ul className="list-inline hstack gap-2 mb-0">
            <li className="list-inline-item edit">
              <button
                className="text-primary d-inline-block btn btn-light"
                onClick={() => handleShow(id)}
              >
                <i className="ri-pencil-fill fs-16"></i>
              </button>
            </li>
            <li className="list-inline-item">
              <button
                className="text-danger d-inline-block btn btn-light"
                onClick={() => handleOpenDeleteModal(id)}
              >
                <i className="ri-delete-bin-5-fill fs-16"></i>
              </button>
            </li>
          </ul>
        );
      },
    },
  ];
  return (
    <>
      {gameList && (
        <DataTable
          columns={columns}
          data={gameList}
          pagination
          customStyles={tableCustomStyles}
        />
      )}
      {/* <Stack direction={"row"} spacing={2}>
        <Button onClick={() => handleShow(null)}>Add New Game</Button>
        <Button onClick={() => nav("/whitelist-users")}>
          Babydoge Whitelist Users
        </Button>
        <Button onClick={() => nav("/nft-create")}>Add New NFT</Button>
        {role === "admin" && (
          <Button onClick={() => nav("/generate-keys")}>Generate Keys</Button>
        )}
      </Stack>*/}
      <EditModal show={show} handleClose={handleClose} data={gameData} />
      <DeleteConsent
        open={deleteModal}
        handleClose={handleCloseDeleteModal}
        data={deleteData}
        handleSubmit={handleDeleteSubmit}
      />
    </>
  );
};

export default DateTableComponent;
