import { create } from "zustand";
import { toast } from "react-hot-toast";
import axios from "../axios/axiosConfig";

export const useKeysStore = create((set) => ({
  keys: null,
  allKeys: null,
  totalPages: 1,
  loading: false,
  updateLoading: false,
  deleteLoading: false,
  getAllKeysLoading: false,
  getKeys: async (quantity) => {
    try {
      set({ loading: true });
      const response = await axios.post("admin/generateKey", { quantity });
      set({ keys: response.data.data.data, loading: false });
    } catch (error) {
      set({ loading: false });
      toast.error(error?.response?.data?.message || "Error. Please Try Again");
    }
  },
  searchKeys: async (search) => {
    try {
      set({ getAllKeysLoading: true });
      const response = await axios.get(`/keys/search/?search=${search}`);
      set({
        allKeys: response.data.keys,
        totalPages: response.data.totalPages,
      });
    } catch (error) {
      set({
        allKeys: [],
        totalPages: 1,
      });
      toast.error(error?.response?.data?.message || "Error. Please Try Again");
    } finally {
      set({ getAllKeysLoading: false });
    }
  },
  getAllKeys: async (page, limit, keyType, type) => {
    try {
      set({ getAllKeysLoading: true });
      const response = await axios.get(
        `keys/getKeys?page=${page}&limit=${limit}&keyType=${keyType}${
          type ? `&type=${type}` : ""
        }`
      );
      set({
        allKeys: response.data.keys,
        totalPages: response.data.totalPages,
        getAllKeysLoading: false,
      });
    } catch (error) {
      set({ getAllKeysLoading: false });
      toast.error(
        error?.response?.data?.message ||
          "Error Fetching Keys, Please Try Again"
      );
    }
  },
  deleteKey: async (id, handleClose) => {
    try {
      set({ deleteLoading: true });
      await axios.delete(`keys/delete/${id}`);
      handleClose();
      toast.success("Deleted Successfully");
    } catch (error) {
      toast.error(error?.response?.data?.message || "Error. Please Try Again");
    } finally {
      set({ deleteLoading: false });
    }
  },
  updateKey: async (id, userId, handleClose) => {
    try {
      set({ updateLoading: true });
      await axios.patch(`/keys/update-primary?id=${id}&userId=${userId}`);
      handleClose();
      toast.success("Updated Successfully");
    } catch (error) {
      toast.error(error?.response?.data?.message || "Error. Please Try Again");
    } finally {
      set({ updateLoading: false });
    }
  },

  clearKeysState: () => {
    set({ keys: null, loading: false });
  },
}));
