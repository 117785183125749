import * as Yup from "yup";
import React from "react";
import { Button, Form, Modal, ToastContainer } from "react-bootstrap";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { useTestUserStore } from "../store/testUserStore";

const NewUserModal = ({ show, handleClose }) => {
  const { createTestUser, loading } = useTestUserStore();

  const formik = useFormik({
    initialValues: {
      gameId: "",
      email: "",
      password: "",
      nickName: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      gameId: Yup.number().required("Game ID is required"),
      email: Yup.string().required("Email is required"),
      password: Yup.string().required("Password is required"),
      nickName: Yup.string().required("Nick Name is required"),
    }),
    onSubmit: async (values) => {
      try {
        const payload = {
          gameId: values?.gameId,
          email: values?.email,
          password: values?.password,
          nickName: values?.nickName,
        };

        const res = await createTestUser(payload);
        if (res?.response?.status === 404 || res?.response?.status === 400) {
          toast.error(res?.response?.data?.data?.message);
        } else {
          formik.resetForm();
          handleClose();
        }
      } catch (error) {
        toast.error("Error adding Game");
      }
    },
  });
  return (
    <>
      <ToastContainer />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Test User</Modal.Title>
        </Modal.Header>
        <Form onSubmit={formik.handleSubmit} noValidate>
          <Modal.Body>
            <Form.Group controlId="validationCustom01">
              <Form.Label>Email</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Email..."
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                isInvalid={formik.touched.email && formik.errors.email}
              />

              {formik.touched.email && formik.errors.email ? (
                <div className="invalid-feedback d-block">
                  {formik.errors.email}
                </div>
              ) : null}
            </Form.Group>
            <Form.Group controlId="validationCustom01">
              <Form.Label>Nick Name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Nick Name..."
                name="nickName"
                value={formik.values.nickName}
                onChange={formik.handleChange}
                isInvalid={formik.touched.nickName && formik.errors.nickName}
              />

              {formik.touched.nickName && formik.errors.nickName ? (
                <div className="invalid-feedback d-block">
                  {formik.errors.nickName}
                </div>
              ) : null}
            </Form.Group>
            <Form.Group controlId="validationCustom02">
              <Form.Label>Password</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="password..."
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                isInvalid={formik.touched.password && formik.errors.password}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="invalid-feedback d-block">
                  {formik.errors.password}
                </div>
              ) : null}
            </Form.Group>
            <Form.Group controlId="validationCustom03">
              <Form.Label>Game ID</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Game ID..."
                name="gameId"
                value={formik.values.gameId}
                onChange={formik.handleChange}
                isInvalid={formik.touched.gameId && formik.errors.gameId}
              />
              {formik.touched.gameId && formik.errors.gameId ? (
                <div className="invalid-feedback d-block">
                  {formik.errors.gameId}
                </div>
              ) : null}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            {loading ? (
              <button className="btn btn-primary" type="button" disabled>
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Loading...
              </button>
            ) : (
              <button className="btn btn-primary" type="submit">
                Create
              </button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default NewUserModal;
