import { create } from "zustand";
import { toast } from "react-hot-toast";
import axios from "../axios/axiosConfig";

export const useAuthStore = create((set) => ({
  admin: null,
  role: null,
  loading: null,
  adminLogin: async (payload) => {
    try {
      set({ loading: true });
      sessionStorage.removeItem("katana-admin-forget-password-token");
      const response = await axios.post("admin/login", {
        email: payload.email,
        password: payload.password,
      });
      toast.success("Success");
      localStorage.setItem("katana-admin-token", response.data.data.token);
      localStorage.setItem("role", response.data.data.user.role);
      payload.navigate("/");
      set({ admin: response.data.data.user, loading: false });
    } catch (error) {
      set({ loading: false });
      toast.error(error.response.data.data.message);
    }
  },
  forgetPassword: async (payload) => {
    try {
      set({ loading: true });
      const res = await axios.post("admin/forgetPassword", {
        email: payload.email,
      });
      sessionStorage.setItem(
        "katana-admin-forget-password-token",
        res.data.data.token
      );
      toast.success("Success, Check Your Email");
      set({ loading: false });
      payload.navigate("/login");
    } catch (error) {
      set({ loading: false });
      toast.error(error.response.data.data.message);
    }
  },
  resetPassword: async (payload) => {
    try {
      set({ loading: true });
      await axios.post(`admin/resetPassword?password=${payload.password}`);
      toast.success("Password Changed");
      set({ loading: false });
      sessionStorage.removeItem("katana-admin-forget-password-token");
      payload.navigate("/login");
    } catch (error) {
      set({ loading: false });
      toast.error(error.response.data.data.message);
    }
  },
  adminLogout: (payload) => {
    toast.success("Logout");
    localStorage.removeItem("katana-admin-token");
    set({ admin: null });
    payload.navigate("/login");
  },
}));
