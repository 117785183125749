import React, { useEffect, useMemo, useState } from "react";
import "./styles.css";
import * as XLSX from "xlsx";
import * as XLSXStyle from "xlsx-js-style";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import { Toaster, toast } from "react-hot-toast";
import "ag-grid-community/styles/ag-theme-balham.css";
import { Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
// import axios from "axios";
import axios from "../../axios/axiosConfig";

const OKXWhitelistTable = () => {
  //testtttt
  const nav = useNavigate();
  const [rowData, setRowData] = useState([]);

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      filter: true,
      floatingFilter: true,
      width: 250,
    };
  }, []);

  const gridOptions = {
    resizable: true,
    enableCellTextSelection: true,
  };

  let initColumns = [
    {
      headerName: "#", // Column for row numbers
      valueGetter: "node.rowIndex + 1", // Value getter to display row numbers
      width: 60, // Adjust the width as needed
      filter: false,
    },
    {
      headerName: "Email",
      field: "email",
    },
    {
      headerName: "Wallet Address",
      field: "walletAddress",
    },
    {
      headerName: "Telegram",
      field: "telegram",
    },
    {
      headerName: "Discord",
      field: "discord",
    },
    {
      headerName: "Twitter",
      field: "twitter",
    },
  ];

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const downloadExcel = () => {
    let excelData = rowData.map((item) => {
      return {
        email: item.email ? item.email : "",
        walletAddress: item.walletAddress ? item.walletAddress : "",
        twitter: item.twitter ? item.twitter : "",
        discord: item.discord ? item.discord : "",
        telegram: item.telegram ? item.telegram : "",
      };
    });

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(excelData);

    // setting width of each column
    worksheet["!cols"] = [{ wch: 30 }, { wch: 50 }, { wch: 30 }, { wch: 30 }];

    // setting font and making heading capitalize
    worksheet["A1"].s = { font: { bold: true, sz: 16 } };
    worksheet["A1"].v = capitalize(worksheet["A1"].v);

    worksheet["B1"].s = { font: { bold: true, sz: 16 } };
    worksheet["B1"].v = capitalize(worksheet["B1"].v);

    worksheet["C1"].s = { font: { bold: true, sz: 16 } };
    worksheet["C1"].v = capitalize(worksheet["C1"].v);

    worksheet["D1"].s = { font: { bold: true, sz: 16 } };
    worksheet["D1"].v = capitalize(worksheet["D1"].v);

    worksheet["E1"].s = { font: { bold: true, sz: 16 } };
    worksheet["E1"].v = capitalize(worksheet["E1"].v);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    // XLSX.writeFile(workbook, "DataSheet.xlsx");

    const buf = XLSXStyle.write(workbook, { bookType: "xlsx", type: "buffer" });

    // Save the Excel file
    const blob = new Blob([buf], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "data.xlsx";
    link.click();
  };

  useEffect(() => {
    axios
      .get("/whitelist-okx")
      .then((response) => {
        setRowData(response.data.data.whitelist);
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.data?.message || "Error Fetching Data"
        );
      });
  }, []);

  const handleCopyField = (value) => {
    let textToCopy = value.data[value.colDef.field];
    navigator.clipboard.writeText(textToCopy);
    toast.success("Copied");
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={() => nav("/")}
        sx={{
          top: 10,
          right: 10,
          fontWeight: 600,
          position: "absolute",
          backgroundColor: "#712083",
        }}
      >
        Back
      </Button>
      <Typography variant="h3" sx={{ alignSelf: "center" }}>
        OKX Whitelist Users
      </Typography>
      <Stack
        marginTop={"1em"}
        sx={{
          marginTop: "1em",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack sx={{ marginBottom: "1em" }}>
          <Button
            variant="contained"
            onClick={downloadExcel}
            sx={{ fontWeight: 600, backgroundColor: "#712083" }}
          >
            Export Excel
          </Button>
        </Stack>
        <div className="ag-theme-balham grid-container">
          <AgGridReact
            rowHeight={35}
            rowData={rowData}
            columnDefs={initColumns}
            gridOptions={gridOptions}
            defaultColDef={defaultColDef}
            onCellDoubleClicked={(value) => handleCopyField(value)}
          />
        </div>
      </Stack>
      <Toaster />
    </>
  );
};

export default OKXWhitelistTable;
