import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ScaleLoader } from "react-spinners";
import { useGameStore } from "../store/gameStore";
import DateTableComponent from "./DateTableComponent";

const GameSettings = () => {
  const { getGame, games, loading } = useGameStore();
  const [gamesList, setGamesList] = useState([]);

  useEffect(() => {
    getGame();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (games) {
      if (games?.length) {
        setGamesList(games);
      } else {
        setGamesList([games]);
      }
    }
  }, [games]);

  return (
    <Stack alignItems={"center"} justifyContent={"center"}>
      {loading ? (
        <ScaleLoader color="#36d7b7" />
      ) : (
        <DateTableComponent gameList={gamesList} />
      )}
    </Stack>
  );
};

export default GameSettings;
