import React, { useEffect, useMemo, useRef, useState } from "react";
import "./tableStyles.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { IconButton, Stack } from "@mui/material";
import { useKeysStore } from "../../store/keysStore";
import Pagination from "@mui/material/Pagination";
import { BsPencilSquare } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { DeleteAlertDialog } from "./DeleteDlg";
import { EditKeyDialog } from "./EditKeyDlg";
import { KeysTabs } from "./Tabs";
import { FilterDropdown } from "./FilterDropdown";
import { KeysForm } from "../KeysForm";
import { SearchBar } from "./SearchBar";
import { JsonToExcel } from "react-json-to-excel";

export const TableData = () => {
  const gridApiRef = useRef(null);
  const { allKeys, totalPages, getAllKeys, getAllKeysLoading } = useKeysStore();

  const [value, setValue] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [keysToExport, setKeysToExport] = useState([]);
  const [selectedKey, setSelectedKey] = useState(null);
  const [showDeleteDlg, setShowDeleteDlg] = useState(false);
  const [showEditDlg, setShowEditDlg] = useState(false);
  const [type, setType] = useState("all");
  const [keyType, setKeyType] = useState("primary");

  useEffect(() => {
    fetchAllKeys();
    // eslint-disable-next-line
  }, [pageNumber, keyType, type]);

  const fetchAllKeys = () => {
    if (keyType === "primary") {
      getAllKeys(pageNumber, 500, keyType, type);
    } else {
      getAllKeys(pageNumber, 500, keyType);
    }
  };

  useEffect(() => {
    if (allKeys && allKeys.length) {
      let keysArray = allKeys.map((item) => {
        return { id: item._id, key: item.key, user: item.userId || "Unused" };
      });

      let keysToExportData = allKeys.map((item) => {
        return { key: item.key };
      });

      setRowData(keysArray);
      setKeysToExport(keysToExportData);
    } else {
      setRowData([]);
      setKeysToExport([]);
    }
  }, [allKeys]);

  useEffect(() => {
    if (gridApiRef?.current) {
      if (getAllKeysLoading) {
        gridApiRef?.current?.showLoadingOverlay();
      } else {
      }
    }
    // eslint-disable-next-line
  }, [gridApiRef?.current, getAllKeysLoading]);

  const defaultColDef = useMemo(() => {
    return { width: 500 };
  }, []);

  const gridOptions = {
    resizable: true,
    enableCellTextSelection: true,
  };

  const ActionsCellRenderer = ({ data }) => {
    return (
      <>
        <IconButton
          disabled={keyType === "special"}
          onClick={() => {
            setSelectedKey(data);
            setShowEditDlg(true);
          }}
        >
          <BsPencilSquare
            color={keyType === "special" ? "grey" : "#1976d2"}
            className="svgStyle"
            size={20}
          />
        </IconButton>
        <IconButton
          disabled={keyType === "special"}
          onClick={() => {
            setShowDeleteDlg(true);
            setSelectedKey(data);
          }}
        >
          <MdDelete
            color={keyType === "special" ? "grey" : "red"}
            className="svgStyle"
            size={20}
          />
        </IconButton>
      </>
    );
  };

  let initColumns = [
    {
      headerName: "#",
      valueGetter: "node.rowIndex + 1",
      width: 60,
      filter: false,
    },
    {
      headerName: "Key",
      field: "key",
    },
    {
      headerName: "User",
      field: "user",
    },
    {
      headerName: "Actions",
      field: "actions",
      editable: false,
      sortable: false,
      filter: false,
      floatingFilter: false,
      width: 150,
      cellRenderer: ActionsCellRenderer,
    },
  ];

  const resetStates = () => {
    setPageNumber(1);
    setKeyType("primary");
    setType("all");
    setValue(0);
  };

  return (
    <>
      <KeysForm resetStates={resetStates} />

      <Stack
        sx={{
          mt: "2em",
          alignItems: "center",
          justifyContent: "center",
          borderTop: "1px solid grey",
        }}
      >
        <Stack
          sx={{
            my: "1em",
            gap: "1em",
            width: "100%",
            flexDirection: "row",
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          <KeysTabs
            value={value}
            setValue={setValue}
            setKeyType={setKeyType}
            setPageNumber={setPageNumber}
          />
          <Stack
            sx={{
              width: "30rem",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SearchBar resetStates={resetStates} />
            <FilterDropdown type={type} setType={setType} />
            {keysToExport?.length ? (
              <JsonToExcel
                btnClassName="excel_button_styles"
                data={keysToExport}
                fileName={"keys"}
                style={{ padding: 10 }}
                title={"Download as Excel"}
              />
            ) : null}
          </Stack>
        </Stack>

        <div className="ag-theme-balham grid-container-table">
          <AgGridReact
            rowHeight={35}
            rowData={rowData}
            columnDefs={initColumns}
            gridOptions={gridOptions}
            defaultColDef={defaultColDef}
            suppressRowClickSelection={true}
            onGridReady={(params) => {
              gridApiRef.current = params.api;
            }}
          />
        </div>

        {/* PAGINATION */}
        <Stack spacing={2} mt={"1em"} alignSelf={"flex-end"}>
          <Pagination
            count={totalPages}
            shape="rounded"
            page={pageNumber}
            variant="outlined"
            onChange={(_, value) => setPageNumber(value)}
          />
        </Stack>

        {/* Dialogs */}
        <DeleteAlertDialog
          showDlg={showDeleteDlg}
          selectedKey={selectedKey}
          handleClose={() => {
            fetchAllKeys();
            setShowDeleteDlg(false);
          }}
        />
        <EditKeyDialog
          showDlg={showEditDlg}
          selectedKey={selectedKey}
          handleClose={() => {
            fetchAllKeys();
            setShowEditDlg(false);
          }}
        />
      </Stack>
    </>
  );
};
