import { Button, Stack } from "@mui/material";
import React, { useState } from "react";

const UploadBuild = () => {
    const [selectedFile, setSelectedFile] = useState(null);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setSelectedFile(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };
  return (
    <Stack height={"80vh"} alignItems={"center"} justifyContent={"center"}>
      <div
        className="file-uploader-container"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <input
          type="file"
          id="fileInput"
          style={{ display: "none" }}
          onChange={handleFileChange}
          accept=".exe"
        />
        <label htmlFor="fileInput" className="custom-upload-button">
          {selectedFile ? (
            <div>
              <p>{selectedFile.name}</p>
              <p>Size: {Math.round(selectedFile.size / 1024)} KB</p>
            </div>
          ) : (
            <p>Drag and drop .exe file here or click to select one.</p>
          )}
        </label>
      </div>
    </Stack>
  );
};

export default UploadBuild;
