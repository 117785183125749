import { Button, Stack, TextField } from "@mui/material";
// import { google } from "googleapis";
import React from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useGameStore } from "../store/gameStore";


// const auth = new google.auth.GoogleAuth({
//     keyFile: __dirname + "/credentials.json",
//     scopes: ["https://www.googleapis.com/auth/drive"]
//   });

//   //google drive instence
//   const drive = google.drive({
//     version: "v3",
//     auth: auth
//   });
const AddGame = () => {
  const { setGame, loading } = useGameStore();

  const [keyValue, setKeyValue] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (keyValue !== null) {
        setGame(keyValue);
    } else {
      toast.error("Please Enter Number");
    }
  };
  return (
    <Stack alignItems={"center"} spacing={2}>
      <TextField
        placeholder="Add Drive Link"
        sx={{ width: "60%" }}
        value={keyValue}
        onClick={(e) => e.target.select()}
        onChange={(e) => setKeyValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") handleSubmit(e);
        }}
      />
      <Button variant="contained" disabled={loading} onClick={handleSubmit}>
        {loading ? "Adding Game..." : "Add Game"}
      </Button>
    </Stack>
  );
};

export default AddGame;
