import axios from "axios";

const axiosConfig = axios.create({
  baseURL: "https://launcherapi.katanainu.com/api/v1/",
  // baseURL: "http://192.168.2.47:5000/api/v1/",
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
});

// Add a request interceptor
axiosConfig.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("katana-admin-token");
    const forget_password_token = sessionStorage.getItem(
      "katana-admin-forget-password-token"
    );

    if (forget_password_token) {
      config.headers["Authorization"] = "Bearer " + forget_password_token;
    }
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosConfig.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export default axiosConfig;
