import React from "react";
import { Navigate } from "react-router";
import { useAuthStore } from "../store/authStore";

const PrivateRoute = ({ children }) => {
  const { admin } = useAuthStore();
  const token = localStorage.getItem("katana-admin-token");
  if (!token && !admin) {
    return <Navigate to={"/login"} replace />;
  }
  return children;
};

export default PrivateRoute;
